"use client";

import { useTranslation } from "@/lib/i18n";
import { fallbackLng } from "@/lib/i18n/settings";
import { Accordion, AccordionItem } from "@nextui-org/react";
import React from "react";

const FAQ: React.FC<{ lng?: string }> = ({ lng = fallbackLng }) => {
  const { t } = useTranslation({
    ns: "website",
    lng,
  });

  const items = [
    {
      q: t("Is TalenCat CV Maker free?"),
      a: t("We offer a free basic"),
    },
    {
      q: t("What should I include in my CV?"),
      a: t("Your CV should contain"),
    },
    {
      q: t("How do I write a CV with no experience?"),
      a: t("If you don’t have any work"),
    },
    {
      q: t("How can I customize my resume?"),
      a: t("As long as you"),
    },
    {
      q: t("Is my data safe?"),
      a: t("Yes, your privacy"),
    },
  ];

  return (
    <section className="m-auto mt-40 max-w-screen-md">
      <h2 className="text-center text-5xl  font-semibold leading-normal text-teal-900">
        {t("Frequently Asked Questions")}
      </h2>

      <div className="mx-auto mt-8 max-w-screen-lg">
        <Accordion
          selectionMode="multiple"
          showDivider={false}
          defaultSelectedKeys="all"
        >
          {items.map((item, index) => (
            <AccordionItem
              key={index}
              aria-label={item.q}
              title={item.q}
              classNames={{
                title: "text-lg font-medium text-emerald-950",
                content: "py-0 text-base text-emerald-950",
                base: "py-2",
                indicator: "text-emerald-950",
              }}
            >
              {item.a}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default FAQ;
