"use client";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { useCallback, useEffect, useState } from "react";
import {
  UseTranslationOptions,
  initReactI18next,
  useTranslation as useTranslationOrg,
} from "react-i18next";
import dayjs from "../dayjs";
import useLocalStorage from "../react-hooks/useLocalStorage";
import {
  Lng,
  fallbackLng,
  getOptions,
  languages,
  localeLocaleStorageKey,
} from "./settings";

const runsOnServerSide = typeof window === "undefined";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./locales/${language}/${namespace}.json`),
    ),
  )
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ["path", "htmlTag", "cookie", "navigator"],
    },
    preload: runsOnServerSide ? languages : [],
  });

export function useTranslation(params?: {
  lng?: string;
  ns?: string;
  options?: UseTranslationOptions<any>;
}) {
  const { ns, options } = params || {};
  // const [cookies, setCookie] = useCookies([localeCookieName]);
  let lng = params?.lng;

  const ret = useTranslationOrg(ns, options);
  const { i18n } = ret;

  const changeLanguage = useCallback(
    (value: string) => {
      let result = value;
      if (!languages.includes(result)) result = fallbackLng;
      i18n.changeLanguage(result);
    },
    [i18n],
  );

  if (runsOnServerSide && lng && i18n.resolvedLanguage !== lng) {
    changeLanguage(lng);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [lngInLocalStorage, setLngInLocalStorage] = useLocalStorage(
      localeLocaleStorageKey,
      fallbackLng,
    );

    lng = lng || lngInLocalStorage;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (activeLng === i18n.resolvedLanguage) return;
      setActiveLng(i18n.resolvedLanguage);
    }, [activeLng, i18n.resolvedLanguage]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!lng || i18n.resolvedLanguage === lng) return;
      changeLanguage(lng);
    }, [lng, i18n, changeLanguage]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (lngInLocalStorage === lng || !lng) return;
      setLngInLocalStorage(lng);
    }, [lng]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!lng) return;
      document.documentElement.lang = lng;
      dayjs.locale(lng);
      setLngInLocalStorage(lng);
    }, [lng]);
  }
  return ret;
}

export function getLanguage() {
  return (i18next.language as Lng) || Lng.en;
}

export function changeClientSideLocale(lng: Lng) {
  if (runsOnServerSide) return;
  i18next.changeLanguage(lng);
  document.documentElement.lang = lng;
  dayjs.locale(lng);
  if (!runsOnServerSide) {
    window.localStorage.setItem(localeLocaleStorageKey, JSON.stringify(lng));
  }
}
