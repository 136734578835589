"use client";

import { useTranslation } from "@/lib/i18n";
import { fallbackLng } from "@/lib/i18n/settings";
import { Button, NavbarItem } from "@nextui-org/react";
import { ArrowRight } from "lucide-react";
import { useSession } from "next-auth/react";
import Link from "next/link";
import React from "react";

const LoginButtonGroup: React.FC<{ lng?: string }> = ({
  lng = fallbackLng,
}) => {
  const { data: session } = useSession();
  const { t } = useTranslation({
    lng: lng,
    ns: "website",
  });

  const loginHref =
    lng === fallbackLng
      ? "/login?callbackUrl=/home"
      : `/${lng}/login?callbackUrl=/home`;

  if (session) {
    return (
      <NavbarItem>
        <Button as={Link} color="primary" href="/home" variant="ghost">
          {t("Create my CV")}
          <ArrowRight size={18} />
        </Button>
      </NavbarItem>
    );
  }

  return (
    <>
      <NavbarItem className="hidden lg:flex">
        <Button
          as={Link}
          href={loginHref}
          variant="light"
          className="text-emerald-900"
        >
          {t("Login")}
        </Button>
      </NavbarItem>
      <NavbarItem>
        <Button
          as={Link}
          color="primary"
          href={loginHref}
          className="shadow-sm"
        >
          {t("Sign Up")}
        </Button>
      </NavbarItem>
    </>
  );
};

export default LoginButtonGroup;
